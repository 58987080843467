import { Component } from "@angular/core";

import { BsModalRef } from "ngx-bootstrap/modal";
import { INotificationModal } from "shared/services/notification.service";

@Component({
    standalone: true,
    imports: [],
    template: `
        @if (messageType == 'error') {
          <div class="modal-icon error">
            <i class="fa fa-2x fa-exclamation-triangle"></i>
          </div>
        }
        @if (messageType == 'question') {
          <div class="modal-icon question">
            <i class="fa fa-2x fa-question-circle"></i>
          </div>
        }
        <div class="modal-body" [innerHTML]="message"></div>
        <div class="modal-footer mx-auto d-block">
          @if (okButtonText && okButtonAction) {
            <button type="button" class="btn btn-blue"
              (click)="ok()">{{ okButtonText }}
            </button>
          }
          @if (otherButtonText && otherButtonAction) {
            <button type="button" class="btn btn-blue"
              (click)="other()">{{ otherButtonText }}
            </button>
          }
          @if (cancelButtonText) {
            <button type="button" class="btn btn-blue"
              (click)="cancel()">{{ cancelButtonText }}
            </button>
          }
        </div>
        `
})
export class NotificationModal implements INotificationModal {
    messageType?: 'error' | 'question';
    message?: string;
    okButtonText?: string = 'Ok';
    okButtonAction?: () => void;
    otherButtonText?: string;
    otherButtonAction?: () => void;
    cancelButtonText?: string;
    cancelButtonAction?: () => void;

    constructor(private readonly modalRef: BsModalRef) {
    }

    ok() {
        if (this.okButtonAction) this.okButtonAction();
        this.modalRef.hide();
    }

    other() {
        if (this.otherButtonAction) this.otherButtonAction();
        this.modalRef.hide();
    }

    cancel() {
        if (this.cancelButtonAction) this.cancelButtonAction();
        this.modalRef.hide();
    }
}
