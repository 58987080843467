import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { NgClass } from '@angular/common';

@Component({
    selector: 'bh-notifications',
    templateUrl: 'notification.component.html',
    standalone: true,
    imports: [NgClass]
})
export class NotificationComponent implements OnInit {
    btnText: string;
    constructor(public notificationService: NotificationService) { }

    ngOnInit() {
        this.notificationService.getBtnText().subscribe(btnText => {
            this.btnText = btnText;
        });
    }
}
