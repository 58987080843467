@if (notificationService.showNotification) {
  <div>
    @if (notificationService.notificationType == 'toast') {
      <div class="toast-message"
        [class.success]="notificationService.messageType == 'success'" [class.error]="notificationService.messageType == 'error'">
        <i class="fa fa-lg"
        [class.fa-check]="notificationService.messageType == 'success'" [class.fa-exclamation-triangle]="notificationService.messageType == 'error'"></i>
        {{notificationService.message}}
        <button type="button" class="btn btn-unstyled" (click)="notificationService.resetNotification()"><i class="fa fa-lg fa-close"></i></button>
      </div>
    }
    @if (notificationService.notificationType == 'modal') {
      <div>
        <div class="modal open notification">
          <div class="modal-center-container">
            <div class="modal-dialog modal-dialog-centered modal-center" [ngClass]="(notificationService.otherBtn !== '') ? 'modal-third-button' : 'modal-sm'">
            </div>
          </div>
        </div>
      </div>
    }
  </div>
}
